import React from "react";

import {
    Navbar,
    NavbarBrand,
    NavbarContent,
    NavbarItem,
    Button,
    DropdownItem,
    DropdownTrigger,
    Dropdown,
    DropdownMenu,
    NavbarMenuToggle, NavbarMenu, Listbox, ListboxItem, ListboxSection, Link
} from "@nextui-org/react";
import {ChevronDown} from "../../Icons/Icons.jsx";
import {DistrictLogoCranberry} from "../../Icons/Logos.jsx";
import {NavLink, Outlet, useNavigate} from "react-router-dom";
import {
    Aaras,
    Ambassadorial,
    Connect3142, Edge3142,
    Finance,
    Home,
    Logout,
    Meetings,
    Membership,
    Projects
} from "../../Icons/NavbarIcons";
import {googleLogout} from "@react-oauth/google";
import {IconWrapper} from "../../Icons/IconWrapper";
import {useDispatch, useSelector} from "react-redux";
import {selectAllUserData, setUser} from "../../redux/slice/userSlice";
import Countdown from "react-countdown";
import {setAllAaraDataNull} from "../../redux/slice/aaraSlice";
import {COMPLETE_ACCESS_LIST, CORE_AND_HIGHER, ONLY_PRES_SEC} from "../../../constants/WebPageConstants";
import {setAllApplicantsDataNull} from "../../redux/slice/application57Slice";
import {setAllFinanceDataNull} from "../../redux/slice/financeSlice";
import {setAllMembershipNull} from "../../redux/slice/membershipSlice";
import {setAllClubDataNull} from "../../redux/slice/clubDataSlice";
import {setAllReportRecordsNull} from "../../redux/slice/reportingSlice";
import {clearRIDData} from "../../redux/slice/ridistrictSlice";

export default function Header() {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const user = useSelector(selectAllUserData);
    const [isMenuOpen, setIsMenuOpen] = React.useState(false);

    const dropDownItemsForReporting = [
        {
            name: "Projects",
            href: "/pranali/projects",
            description: "Conducted a project and want to report it? Sure, go ahead, or you can simply view your previous reports.",
            icon: isMenuOpen
                ? <IconWrapper className="bg-danger/10 text-danger">
                    <Projects className="text-danger" fill="currentColor" size={20}/>
                </IconWrapper>
                : <Projects className="text-danger" fill="currentColor" size={30}/>
        },
        {
            name: "Meetings",
            href: "/pranali/meetings",
            description: "Completed your quorum and held a GBM, BOD, or OCV? What are you waiting for? Report it here.",
            icon: isMenuOpen
                ? <IconWrapper className="bg-warning/10 text-warning">
                    <Meetings className="text-warning" fill="currentColor" size={20}/>
                </IconWrapper>
                : <Meetings className="text-warning" fill="currentColor" size={30}/>
        },
        {
            name: "Ambassadorial",
            href: "/pranali/ambassadorials",
            description: "Report attendance of an ambassador and representation of your club at PIS and other Rotaract club events!",
            icon: isMenuOpen
                ? <IconWrapper className="bg-secondary/10 text-secondary">
                    <Ambassadorial className="text-secondary" fill="currentColor" size={20}/>
                </IconWrapper>
                : <Ambassadorial className="text-secondary" fill="currentColor" size={30}/>
        },
    ];

    const dropDownItemsForClubAdmin = [
        {
            name: "Membership",
            href: "/pranali/membership",
            description: "A module to add, update, or terminate a member's membership and information, and retrieve a list of all current members in the district.",
            icon: isMenuOpen
                ? <IconWrapper className="bg-primary/10 text-primary">
                    <Membership className="text-primary" fill="currentColor" size={20}/>
                </IconWrapper>
                : <Membership className="text-primary" fill="currentColor" size={30}/>
        },
        {
            name: "Finances",
            href: "/pranali/finances",
            description: "A real-time feature to add money to the wallet and view an overview of transactions.",
            icon: isMenuOpen
                ? <IconWrapper className="bg-success/10 text-success">
                    <Finance className="text-success" fill="currentColor" size={20}/>
                </IconWrapper>
                : <Finance className="text-success" fill="currentColor" size={30}/>
        },
        {
            name: "AARAs",
            href: "/pranali/aara",
            description: "Nominate or view your projects for the AARAs.",
            icon: isMenuOpen
                ? <IconWrapper className="bg-warning/10 text-warning">
                    <Aaras className="text-warning" fill="currentColor" size={20}/>
                </IconWrapper>
                : <Aaras className="text-warning" fill="currentColor" size={30}/>
        }
    ];

    const dropDownItemsForDistrictAdmin = [
        {
            name: "Clubs in 3142",
            href: "/pranali/clubs-3142",
            description: "Want to know how many club's are there in district and how they are performing ?",
        },
        {
            name: "Nominations",
            href: "/pranali/aara/view-nominations",
            description: "Want to know how many club's have filled nominations.",
            // icon: isMenuOpen
            //     ? <IconWrapper className="bg-warning/10 text-warning">
            //         <Nomination className="text-warning" fill="currentColor" size={20}/>
            //     </IconWrapper>
            //     : <Nomination className="text-warning" fill="currentColor" size={30}/>
        },
        {
            name: "Administration",
            href: "/pranali/administration",
            description: "Control the deadline and data of your home district",
        },
    ];

    const dropDownItemsForRotaractors = [
        /*{
            name: "Edge 3142",
            href: "/pranali/discounts",
            description: "Enjoy discounts at partnered brands with RID 3142.",
            icon: isMenuOpen
                ? <IconWrapper className="bg-warning/10 text-warning">
                    <Edge3142 className="text-warning" fill="currentColor" size={20}/>
                </IconWrapper>
                : <Edge3142 className="text-warning" fill="currentColor" size={30}/>
        },*/
        {
            name: "Connect 3142",
            href: "https://drive.google.com/file/d/1C0wZ95_yQkThR62PNP0tBVhthkHm4Ba2/view?usp=sharing",
            description: "Promoting Rotaractor-owned businesses and facilitating networking through public business data sharing.",
            icon: isMenuOpen
                ? <IconWrapper className="bg-primary/10 text-primary">
                    <Connect3142 className="text-primary" fill="currentColor" size={20}/>
                </IconWrapper>
                : <Connect3142 className="text-primary" fill="currentColor" size={30}/>
        },
    ];

    const icons = {
        chevron: <ChevronDown fill="currentColor" size={16}/>,
        logout: <Logout className="text-danger" fill="currentColor" size={23}/>
    };

    function cleanUpAndLogout() {
        googleLogout();
        dispatch(setUser(null));
        dispatch(setAllAaraDataNull());
        dispatch(setAllApplicantsDataNull());
        dispatch(setAllFinanceDataNull());
        dispatch(setAllMembershipNull());
        dispatch(setAllClubDataNull(null));
        dispatch(setAllReportRecordsNull());
        dispatch(clearRIDData());
        navigate("/");
    }

    const renderer = ({minutes, seconds, completed}) => {
        if (!completed) {
            return <span>{minutes}:{seconds}</span>;
        } else {
            cleanUpAndLogout();
        }
    };

    return (
        <>
            <Navbar isBordered isMenuOpen={isMenuOpen} onMenuOpenChange={setIsMenuOpen}>
                <NavbarContent className="sm:hidden" justify="start">
                    <NavbarMenuToggle aria-label={isMenuOpen ? "Close menu" : "Open menu"}/>
                </NavbarContent>
                <NavbarContent justify="start">
                    <NavbarBrand>
                        <DistrictLogoCranberry/>
                    </NavbarBrand>
                </NavbarContent>
                <NavbarContent className="hidden sm:flex gap-4" justify="center">
                    <NavbarItem isActive>
                        <NavLink to="/pranali" key="Home">
                            Home
                        </NavLink>
                    </NavbarItem>
                    {!user.isAdminDuesPaid && !CORE_AND_HIGHER.includes(user.pranaliAccess) && <NavbarItem>
                        <NavLink to="/pranali/finances" key="Finances">
                            Finances
                        </NavLink>
                    </NavbarItem>}
                    {((COMPLETE_ACCESS_LIST.includes(user.pranaliAccess) && user.isAdminDuesPaid) || CORE_AND_HIGHER.includes(user.pranaliAccess)) && <>
                        <Dropdown>
                            <NavbarItem>
                                <DropdownTrigger>
                                    <Button
                                        disableRipple
                                        className="p-0 bg-transparent data-[hover=true]:bg-transparent"
                                        endContent={icons.chevron}
                                        radius="sm"
                                        variant="light">
                                        Club Administration
                                    </Button>
                                </DropdownTrigger>
                            </NavbarItem>
                            <DropdownMenu color="secondary" variant="bordered" aria-label="Club Admin features"
                                          className="w-[340px]" itemClasses={{base: "gap-4"}}>
                                {dropDownItemsForClubAdmin.map(menuItems => (
                                    <DropdownItem
                                        key={menuItems.name}
                                        description={menuItems.description}
                                        onClick={() => navigate(menuItems.href)}
                                        startContent={menuItems.icon}>
                                        {menuItems.name}
                                    </DropdownItem>
                                ))}
                            </DropdownMenu>
                        </Dropdown>
                        <Dropdown>
                            <NavbarItem>
                                <DropdownTrigger>
                                    <Button
                                        disableRipple
                                        className="p-0 bg-transparent data-[hover=true]:bg-transparent"
                                        endContent={icons.chevron} radius="sm" variant="light">
                                        Reporting
                                    </Button>
                                </DropdownTrigger>
                            </NavbarItem>
                            <DropdownMenu color="secondary" variant="bordered" aria-label="Reporting features"
                                          className="w-[340px]" itemClasses={{base: "gap-4"}}>
                                {dropDownItemsForReporting.map(menuItems => (
                                    <DropdownItem
                                        key={menuItems.name}
                                        description={menuItems.description}
                                        onClick={() => navigate(menuItems.href)}
                                        startContent={menuItems.icon}>
                                        {menuItems.name}
                                    </DropdownItem>
                                ))}
                            </DropdownMenu>
                        </Dropdown>
                        <Dropdown>
                            <NavbarItem>
                                <DropdownTrigger>
                                    <Button
                                        disableRipple
                                        className="p-0 bg-transparent data-[hover=true]:bg-transparent"
                                        endContent={icons.chevron} radius="sm" variant="light">
                                        For Rotaractors
                                    </Button>
                                </DropdownTrigger>
                            </NavbarItem>
                            <DropdownMenu color="secondary" variant="bordered" aria-label="Reporting features"
                                          className="w-[340px]" itemClasses={{base: "gap-4"}}>
                                {dropDownItemsForRotaractors.map(menuItems => (
                                    <DropdownItem
                                        key={menuItems.name}
                                        description={menuItems.description}
                                        onClick={() => window.open(menuItems.href, "_blank")}
                                        startContent={menuItems.icon}>
                                        {menuItems.name}
                                    </DropdownItem>
                                ))}
                            </DropdownMenu>
                        </Dropdown>
                    </>}
                    {!ONLY_PRES_SEC.includes(user.pranaliAccess) &&
                        <Dropdown>
                            <NavbarItem>
                                <DropdownTrigger>
                                    <Button
                                        disableRipple
                                        className="p-0 bg-transparent data-[hover=true]:bg-transparent"
                                        endContent={icons.chevron}
                                        radius="sm"
                                        variant="light">
                                        District Admin
                                    </Button>
                                </DropdownTrigger>
                            </NavbarItem>
                            <DropdownMenu color="secondary" variant="bordered" aria-label="District Admin features"
                                          className="w-[340px]" itemClasses={{base: "gap-4"}}>
                                {dropDownItemsForDistrictAdmin.map(menuItems => (
                                    <DropdownItem
                                        key={menuItems.name}
                                        description={menuItems.description}
                                        onClick={() => navigate(menuItems.href)}
                                        startContent={menuItems.icon}>
                                        {menuItems.name}
                                    </DropdownItem>
                                ))}
                            </DropdownMenu>
                        </Dropdown>
                    }
                    <NavbarItem>
                        <Link color="foreground" href="https://forms.gle/4RCt3PZ29C2CEuiA8" target="_blank" key="help">
                            Help
                        </Link>
                    </NavbarItem>
                </NavbarContent>
                <NavbarContent justify="end">
                    <NavbarItem>
                        <div className="w-full flex flex-col text-center content-center">
                            <Countdown date={(user.expiryDate - 200) * 1000} renderer={renderer}/>
                            <p className="text-xs text-gray-600">Time until logout</p>
                        </div>
                    </NavbarItem>
                    <NavbarItem className="hidden sm:flex">
                        <Button color="danger" variant="light" endContent={icons.logout}
                                onClick={() => cleanUpAndLogout()}>
                            Logout
                        </Button>
                    </NavbarItem>
                </NavbarContent>

                <NavbarMenu>
                    <Listbox>
                        <ListboxItem
                            key="home"
                            startContent={
                                <IconWrapper className="bg-secondary/10 text-secondary">
                                    <Home className="text-secondary" fill="currentColor" size={20}/>
                                </IconWrapper>
                            }
                            onClick={() => {
                                setIsMenuOpen(false);
                                navigate("/pranali");
                            }}>
                            Home
                        </ListboxItem>
                        {!user.isAdminDuesPaid && !CORE_AND_HIGHER.includes(user.pranaliAccess) && <ListboxItem
                            key="Finances"
                            startContent={
                                <IconWrapper className="bg-success/10 text-success">
                                    <Finance className="text-success" fill="currentColor" size={20}/>
                                </IconWrapper>
                            }
                            onClick={() => {
                                setIsMenuOpen(false);
                                navigate("/pranali/finances");
                            }}>
                            Finances
                        </ListboxItem>}
                        {((COMPLETE_ACCESS_LIST.includes(user.pranaliAccess) && user.isAdminDuesPaid) || CORE_AND_HIGHER.includes(user.pranaliAccess)) &&
                            <ListboxSection title="Club Admin" showDivider>
                                {dropDownItemsForClubAdmin.map((item, index) => (<ListboxItem
                                    key={index}
                                    startContent={item.icon}
                                    onClick={() => {
                                        setIsMenuOpen(false);
                                        navigate(item.href)
                                    }}>
                                    {item.name}
                                </ListboxItem>))}
                            </ListboxSection>
                        }

                        {((COMPLETE_ACCESS_LIST.includes(user.pranaliAccess) && user.isAdminDuesPaid) || CORE_AND_HIGHER.includes(user.pranaliAccess)) &&
                            <ListboxSection title="Reporting" showDivider>
                                {dropDownItemsForReporting.map((item, index) => (<ListboxItem
                                    key={index}
                                    startContent={item.icon}
                                    onClick={() => {
                                        setIsMenuOpen(false);
                                        navigate(item.href)
                                    }}>
                                    {item.name}
                                </ListboxItem>))}
                            </ListboxSection>
                        }

                        {((COMPLETE_ACCESS_LIST.includes(user.pranaliAccess) && user.isAdminDuesPaid) || CORE_AND_HIGHER.includes(user.pranaliAccess)) &&
                            <ListboxSection title="For Rotaractors" showDivider>
                                {dropDownItemsForRotaractors.map((item, index) => (<ListboxItem
                                    key={index}
                                    startContent={item.icon}
                                    onClick={() => {
                                        setIsMenuOpen(false);
                                        window.open(item.href, "_blank");
                                    }}>
                                    {item.name}
                                </ListboxItem>))}
                            </ListboxSection>
                        }

                        {!ONLY_PRES_SEC.includes(user.pranaliAccess) &&
                            <ListboxSection title="District Admin" showDivider>
                                {dropDownItemsForDistrictAdmin.map((item, index) => (<ListboxItem
                                    key={index}
                                    startContent={item.icon}
                                    onClick={() => {
                                        setIsMenuOpen(false);
                                        navigate(item.href)
                                    }}>
                                    {item.name}
                                </ListboxItem>))}
                            </ListboxSection>
                        }

                        <ListboxItem
                            key="help"
                            startContent={
                                <IconWrapper className="bg-secondary/10 text-secondary">
                                    <Home className="text-secondary" fill="currentColor" size={20}/>
                                </IconWrapper>
                            }
                            onClick={() => {
                                setIsMenuOpen(false);
                                window.open("https://forms.gle/4RCt3PZ29C2CEuiA8", "_blank");
                            }}>
                            Help
                        </ListboxItem>

                        <ListboxItem key="logout" color="danger" className="text-danger" startContent={icons.logout}
                                     onClick={() => cleanUpAndLogout()}>
                            Logout
                        </ListboxItem>
                    </Listbox>
                </NavbarMenu>
            </Navbar>
            <div className="m-5">
                <Outlet/>
            </div>
        </>
    );
}
