import {useDispatch, useSelector} from "react-redux";
import {selectAllUserData} from "../../redux/slice/userSlice";
import {ToastContainer} from "react-toastify";
import React, {useEffect, useState} from "react";
import {getFetchDataStatus, selectRIDData} from "../../redux/slice/ridistrictSlice";
import {getHomeDistrictData} from "../../../services/RIDistrictServices";
import {
    Button,
    Card,
    CardBody, CardFooter, DatePicker,
    Modal,
    ModalBody,
    ModalContent,
    ModalFooter,
    ModalHeader, Select, SelectItem,
    Skeleton,
    useDisclosure
} from "@nextui-org/react";
import {
    CalendarIcon,
    CountIcon,
    DateAndTimeIcon,
    EditIcon,
    EmailIcon,
    LocationIcon,
    UserLoveIcon
} from "../../Icons/Icons";
import {IconWrapperSize14} from "../../Icons/IconWrapper";
import {DD_MONTHS} from "../../../constants/dropdownList";
import {Add, Minus} from "../../Icons/SystemIcons";
import {getLocalTimeZone, now, parseAbsoluteToLocal} from "@internationalized/date";

export default function DistrictAdmin() {
    const user = useSelector(selectAllUserData);
    const dispatch = useDispatch();

    const ridData = useSelector(selectRIDData);
    const ridDataFetchStatus = useSelector(getFetchDataStatus);

    const {isOpen, onOpen, onOpenChange} = useDisclosure();
    const [deadlineFor, setDeadlineFor] = useState(0);

    const [deadlinesReports, setDeadlinesReports] = useState(ridData?.reportDeadline ? [...convertReportDeadlineDate(ridData.reportDeadline)] : []);
    const [deadlinesAwards, setDeadlinesAwards] = useState(ridData?.awardsDetails ? ridData.awardsDetails : []);

    function convertReportDeadlineDate(value) {
        for (const valueElement of value) {
            let date = new Date(valueElement.deadline.replace("T", " ")).toISOString();
            valueElement.deadline = parseAbsoluteToLocal(date);
            // tempList.push(valueElement);
        }
        console.log(value);
        return value;
    }

    const addFields = () => {
        if (deadlineFor === 1)
            setDeadlinesReports([...deadlinesReports, {month: "", deadline: now(getLocalTimeZone())}])
        else
            setDeadlinesAwards([...deadlinesAwards, {
                nominationType: "",
                startDate: now(getLocalTimeZone()),
                endDate: now(getLocalTimeZone())
            }])
    }

    const removeFormFields = (i) => {
        let updatedValues = deadlinesReports;
        updatedValues.splice(i, 1);
        setDeadlinesReports([...updatedValues]);
    }

    useEffect(() => {
        if (ridDataFetchStatus === 'idle')
            getHomeDistrictData(dispatch, user);
    }, []);

    return (
        <div className="flex flex-col gap-4 sm:gap-8">
            <ToastContainer/>
            <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-4">
                <Card shadow="sm">
                    <CardBody className="flex flex-row gap-x-2 items-center">
                        <IconWrapperSize14 className="bg-danger/10 text-danger">
                            <LocationIcon className="text-danger" fill="currentColor" size={40}/>
                        </IconWrapperSize14>
                        <div className="flex flex-col w-full">
                            <Skeleton isLoaded={ridDataFetchStatus === 'completed'}
                                      className={"rounded-lg " + (ridDataFetchStatus === 'completed' ? 'w-full' : 'w-4/5')}>
                                <p className="text-md font-medium">{ridData?.rid}</p>
                            </Skeleton>
                            <p className="text-sm text-default-500">Home R.I.D</p>
                        </div>
                    </CardBody>
                </Card>

                <Card shadow="sm">
                    <CardBody className="flex flex-row gap-x-2 items-center">
                        <IconWrapperSize14 className="bg-success/10 text-success">
                            <CountIcon className="text-success" fill="currentColor" size={40}/>
                        </IconWrapperSize14>
                        <div className="flex flex-col w-full">
                            <Skeleton isLoaded={ridDataFetchStatus === 'completed'}
                                      className={"rounded-lg " + (ridDataFetchStatus === 'completed' ? 'w-full' : 'w-4/5')}>
                                <p className="text-md font-medium">{ridData?.zonesCount}</p>
                            </Skeleton>
                            <p className="text-sm text-default-500">Number of Zones</p>
                        </div>
                    </CardBody>
                </Card>

                <Card shadow="sm">
                    <CardBody className="flex flex-row gap-x-2 items-center">
                        <IconWrapperSize14 className="bg-primary/10 text-primary">
                            <EmailIcon className="text-primary" fill="currentColor" size={40}/>
                        </IconWrapperSize14>
                        <div className="flex flex-col w-full">
                            <Skeleton isLoaded={ridDataFetchStatus === 'completed'}
                                      className={"rounded-lg " + (ridDataFetchStatus === 'completed' ? 'w-full' : 'w-4/5')}>
                                <p className="text-md font-medium">{ridData?.districtEmailId}</p>
                            </Skeleton>
                            <p className="text-sm text-default-500">Email Id</p>
                        </div>
                    </CardBody>
                </Card>

                <Card shadow="sm">
                    <CardBody className="flex flex-row gap-x-2 items-center">
                        <IconWrapperSize14 className="bg-warning/10 text-warning">
                            <DateAndTimeIcon className="text-warning" fill="currentColor" size={40}/>
                        </IconWrapperSize14>
                        <div className="flex flex-col w-full">
                            <Skeleton isLoaded={ridDataFetchStatus === 'completed'}
                                      className={"rounded-lg " + (ridDataFetchStatus === 'completed' ? 'w-full' : 'w-4/5')}>
                                <p className="text-md font-medium">{ridData?.localDateTimeZoneId}</p>
                            </Skeleton>
                            <p className="text-sm text-default-500">Operating Time Zone</p>
                        </div>
                    </CardBody>
                </Card>

                <Card shadow="sm">
                    <CardBody className="flex flex-row gap-x-2 items-center">
                        <IconWrapperSize14 className="bg-secondary/10 text-secondary">
                            <UserLoveIcon className="text-secondary" fill="currentColor" size={40}/>
                        </IconWrapperSize14>
                        <div className="flex flex-col w-full">
                            <Skeleton isLoaded={ridDataFetchStatus === 'completed'}
                                      className={"rounded-lg " + (ridDataFetchStatus === 'completed' ? 'w-full' : 'w-4/5')}>
                                <p className="text-md font-medium">Rtr. {ridData?.drrName}</p>
                            </Skeleton>
                            <p className="text-sm text-default-500">DRR</p>
                        </div>
                    </CardBody>
                </Card>

                <Card shadow="sm">
                    <CardBody className="flex flex-row gap-x-2 items-center">
                        <IconWrapperSize14 className="bg-warning/10 text-warning">
                            <UserLoveIcon className="text-warning" fill="currentColor" size={40}/>
                        </IconWrapperSize14>
                        <div className="flex flex-col w-full">
                            <Skeleton isLoaded={ridDataFetchStatus === 'completed'}
                                      className={"rounded-lg " + (ridDataFetchStatus === 'completed' ? 'w-full' : 'w-4/5')}>
                                <p className="text-md font-medium">Rtr. {ridData?.drsName}</p>
                            </Skeleton>
                            <p className="text-sm text-default-500">DRS</p>
                        </div>
                    </CardBody>
                </Card>

                <Card shadow="sm">
                    <CardBody className="flex flex-row gap-x-2 items-center">
                        <IconWrapperSize14 className="bg-danger/10 text-danger">
                            <UserLoveIcon className="text-danger" fill="currentColor" size={40}/>
                        </IconWrapperSize14>
                        <div className="flex flex-col w-full">
                            <Skeleton isLoaded={ridDataFetchStatus === 'completed'}
                                      className={"rounded-lg " + (ridDataFetchStatus === 'completed' ? 'w-full' : 'w-4/5')}>
                                <p className="text-md font-medium">Rtr. {ridData?.drreName}</p>
                            </Skeleton>
                            <p className="text-sm text-default-500">DRRE</p>
                        </div>
                    </CardBody>
                </Card>

                <div className="col-span-full flex gap-3 items-center mt-5 px-2">
                    <h2 className="font-semibold text-2xl antialiased">Reporting Deadlines</h2>
                    <span className="text-lg text-secondary-400 cursor-pointer active:opacity-50" onClick={() => {
                        setDeadlineFor(1);
                        onOpen();
                    }}>
                        <EditIcon/>
                    </span>
                </div>
                {ridData?.reportDeadline.map((item, index) =>
                    <Card key={index} shadow="sm">
                        <CardBody className="flex flex-row gap-x-2 items-center">
                            <IconWrapperSize14 className="bg-success/10 text-success">
                                <DateAndTimeIcon className="text-success" fill="currentColor" size={40}/>
                            </IconWrapperSize14>
                            <div className="flex flex-col">
                                <p className="text-md font-medium">{new Date(item.deadline.replaceAll("T", " ")).toLocaleString()}</p>
                                <p className="text-sm text-default-500">{item.month}</p>
                            </div>
                        </CardBody>
                    </Card>
                )}

                <div className="col-span-full flex gap-3 items-center mt-5 px-2">
                    <h2 className="font-semibold text-2xl antialiased">Award Deadlines</h2>
                    {/*<Button size="sm" isIconOnly color="secondary">*/}
                    {/*    <EditIcon/>*/}
                    {/*</Button>*/}
                    <span className="text-lg text-secondary-400 cursor-pointer active:opacity-50" onClick={() => {
                        setDeadlineFor(2);
                        onOpen();
                    }}>
                        <EditIcon/>
                    </span>
                </div>

                {ridData?.awardsDetails?.map((item, index) =>
                    <Card key={index} shadow="sm">
                        <CardBody className="flex flex-row gap-x-2 items-center">
                            <IconWrapperSize14 className="bg-secondary/10 text-secondary">
                                <CalendarIcon className="text-secondary" fill="currentColor" size={40}/>
                            </IconWrapperSize14>
                            <div className="flex flex-col">
                                <p className="text-md font-medium">{item.nominationType}</p>
                                <p className="text-sm text-default-500">
                                    {new Date(item.startDate.replaceAll("T", " ")).toLocaleString()} -
                                    {new Date(item.endDate.replaceAll("T", " ")).toLocaleString()}
                                </p>
                            </div>
                        </CardBody>
                    </Card>
                )}
            </div>

            <Modal
                isOpen={isOpen}
                size={"5xl"}
                placement="auto"
                onOpenChange={onOpenChange}
            >
                <ModalContent>
                    {(onClose) => (
                        <>
                            <ModalHeader
                                className="flex flex-col gap-1">Update {deadlineFor === 1 ? "Report" : "Award"} Deadlines
                            </ModalHeader>
                            <ModalBody>
                                <div className=" w-full flex flex-row flex-wrap gap-4">
                                    {deadlinesReports.map((forReporting, index) => (
                                        <Card>
                                            <CardBody>
                                                <Select
                                                    isRequired
                                                    label="Reporting Month"
                                                    defaultSelectedKeys={[forReporting.month]}
                                                    variant="underlined"
                                                    className="max-w-md col-span-6"
                                                    // onChange={e => addProjectDetailsToNominations(index, e)}
                                                >
                                                    {DD_MONTHS.map(data =>
                                                        <SelectItem key={data.value} value={data.value}>
                                                            {data.label}
                                                        </SelectItem>
                                                    )}
                                                </Select>
                                                <DatePicker
                                                    label="Deadline"
                                                    description={"Date & Time Format : mm/dd/yyyy - hh:mm."}
                                                    className="max-w-md col-span-6"
                                                    variant="underlined"
                                                    granularity="second"
                                                    hideTimeZone
                                                    value={forReporting.deadline}
                                                    // onChange={}
                                                    maxValue={now(getLocalTimeZone())}
                                                    showMonthAndYearPickers
                                                />
                                            </CardBody>
                                            <CardFooter>
                                                {deadlinesReports.length > 1
                                                    ? <Button isIconOnlytype="button" color="danger" variant="flat"
                                                              className="w-auto col-span-1 sm:col-span-1 md:order-4"
                                                              onClick={() => removeFormFields(index)}>
                                                        <Minus fill="currentColor"
                                                               size={28}/>
                                                    </Button>
                                                    : null}
                                            </CardFooter>
                                        </Card>
                                    ))}
                                </div>
                                <div className="mt-10 w-full flex justify-center">
                                    <Button className="max-w-sm" color="primary" variant="bordered" startContent={
                                        <Add fill="currentColor" size={28}/>
                                    } onClick={addFields}>
                                        Add more
                                    </Button>
                                </div>

                            </ModalBody>
                            <ModalFooter>
                                <Button color="danger" variant="light" onPress={onClose}>
                                    Close
                                </Button>
                                <Button color="primary" onPress={onClose}>
                                    Action
                                </Button>
                            </ModalFooter>
                        </>
                    )}
                </ModalContent>
            </Modal>
        </div>
    );
}