import axios from "axios";
import ApiEndpoint from "./ApiEndpoint";
import {throwErrorToast} from "../components/admin/aaras/NewNomination";

export function onBoardNewClub(reqBody, user) {
    const apiEndPoint = new ApiEndpoint();

    let returnableObj = {
        clubId: null,
        clubName: null,
        isLoading: true
    }

    return axios(apiEndPoint.onBoardNewClub(user, reqBody)).then((response) => {
        if (response.status === 201) {
            returnableObj.clubId = response.body.clubId;
            returnableObj.clubName = response.body.clubName;
            returnableObj.isLoading = false;
            return returnableObj;
        }
    }).catch(e => throwErrorToast(e));
}